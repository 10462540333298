<template>
  <v-container>
    <v-progress-linear v-if="isLoading" indeterminate></v-progress-linear>
    <template-form ref="templateForm" v-bind:name="name" v-bind:form-fields="formFields" v-bind:url="url" v-bind:localStorageKey="'MPUForm'" />
  </v-container>
</template>

<script>

import TemplateForm from "@/components/TemplateForm";
import {getContactsURL, getMpuURL} from "@/configBuilder";
export default {
  name: "MPUFormView",
  components: {TemplateForm },
  data: () => ({
    name: "MPU Formular",
    url: getMpuURL(),
    isLoading: false,
  }),
  methods: {
    test() {
      // console.log(this.formFields);
    },
    syncHTMLRenderer() {
      this.$forceUpdate();
    },
  },
  computed: {
    errorMessage() {
      return JSON.parse(JSON.stringify(this.$t('error.validation')));
    },
    formFields(){
      return [
        {name: "Allgemeine Kundeninformationen", color: "background--lighten-1", flat: true, md: 12, cols:  [
            {
              model: "",
              name: "customerName",
              type: "textField",
              label: "Vorname",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
              ],
              counter: 0,
              autoGrow: false,
              required: true,
              md: 3,
            },
            {
              model: "",
              name: "customerLastname",
              type: "textField",
              label: "Nachname",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
              ],
              counter: 0,
              autoGrow: false,
              required: true,
              md: 3,
            },
            {
              model: "",
              name: "customerMailAddress",
              type: "textField",
              label: "E-Mail",
              color: "",
              rules: [
                v => /^\S+@\S+\.\S+$/.test(v) || this.errorMessage["validEmail"],
                v => !!v || this.errorMessage.required,
              ],
              counter: 0,
              autoGrow: false,
              required: true,
              md: 3,
            },
            {
              model: "",
              name: "customerMobileNumber",
              type: "textField",
              label: "Telefonnummer",
              color: "",
              fieldType: "",
              rules: [
                v => /\+[0-9]{2}[1-9][0-9]{1,25}/.test(v) || this.errorMessage["validPhone"],
                v => !!v || this.errorMessage.required,
              ],
              counter: 0,
              autoGrow: false,
              required: true,
              md: 3,
            },
          ],},
        {name: "Wohnort des Kunden", color: "background--lighten-1", flat: false, md: 12, cols:  [
            {
              model: "",
              name: "customerStreet",
              type: "textField",
              label: "Strasse",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
              ],
              counter: 0,
              autoGrow: false,
              required: true,
              md: 3,
            },
            {
              model: "",
              name: "customerStreetnumber",
              type: "textField",
              label: "Hausnummer",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
              ],
              counter: 0,
              autoGrow: false,
              required: true,
              md: 3,
            },
            {
              model: "",
              name: "customerZipCode",
              type: "textField",
              fieldType: "number",
              label: "Postleitzahl",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
              ],
              counter: 0,
              autoGrow: false,
              required: true,
              md: 3,
            },
            {
              model: "",
              name: "customerCity",
              type: "textField",
              label: "Stadt",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
              ],
              counter: 0,
              autoGrow: false,
              required: true,
              md: 3,
            },
          ],},
        {name: "Falldaten", color: "background--lighten-1", flat: false, md: 12, cols:  [
            {
              model: "",
              name: "reason",
              type: "textarea",
              label: "Grund",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
                v => (v && v.length <= 8000) || "Notiz darf nicht länger als 8000 Zeichen sein",
              ],
              counter: 8000,
              autoGrow: false,
              required: true,
              md: 12,
            },
            {
              model: undefined,
              name: "numberOfBookedAppointments",
              type: "textField",
              label: "Anzahl an buchbaren Terminen",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
                v => (v && v > 0 && v < 100) || "",
              ],
              counter: 2,
              autoGrow: false,
              required: true,
              md: "auto",
            },
          ],},
        {name: "Vermittler", color: "background--lighten-2", flat: false, md: 12, cols:  [
            {
              model: null, // id only
              name: "intermediaryAgent",
              type: "combobox",
              label: "Vermittler",
              items: [],
              itemText: "name",
              itemFoundInItems: false,
              createURL: getContactsURL(),
              createFields: [
                {
                  model: "",
                  name: "mailAddress",
                  type: "textField",
                  label: "E-Mail",
                  color: "",
                  rules: [
                    v => /^\S+@\S+\.\S+$/.test(v) || this.errorMessage["validEmail"],
                    v => !!v || this.errorMessage.required,
                  ],
                  counter: 0,
                  autoGrow: false,
                  required: true,
                  md: 3,
                },
                {
                  model: "",
                  name: "mobileNumber",
                  type: "textField",
                  label: "Telefonnummer",
                  color: "",
                  fieldType: "",
                  rules: [
                    v => /\+[0-9]{2}[1-9][0-9]{1,25}/.test(v) || this.errorMessage["validPhone"],
                    v => !!v || this.errorMessage.required,
                  ],
                  counter: 0,
                  autoGrow: false,
                  required: true,
                  md: 3,
                },
                {
                  model: "",
                  name: "street",
                  type: "textField",
                  label: "Strasse",
                  color: "",
                  rules: [
                    v => !!v || this.errorMessage.required,
                  ],
                  counter: 0,
                  autoGrow: false,
                  required: true,
                  md: 3,
                },
                {
                  model: "",
                  name: "streetnumber",
                  type: "textField",
                  label: "Hausnummer",
                  color: "",
                  rules: [
                    v => !!v || this.errorMessage.required,
                  ],
                  counter: 0,
                  autoGrow: false,
                  required: true,
                  md: 3,
                },
                {
                  model: "",
                  name: "zipCode",
                  type: "textField",
                  fieldType: "number",
                  label: "Postleitzahl",
                  color: "",
                  rules: [
                    v => !!v || this.errorMessage.required,
                  ],
                  counter: 0,
                  autoGrow: false,
                  required: true,
                  md: 3,
                },
                {
                  model: "",
                  name: "city",
                  type: "textField",
                  label: "Stadt",
                  color: "",
                  rules: [
                    v => !!v || this.errorMessage.required,
                  ],
                  counter: 0,
                  autoGrow: false,
                  required: true,
                  md: 3,
                },
              ],
              search: "",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
              ],
              autoGrow: false,
              required: false,
              md: 12,
            },
          ],},
      ]
    }
  },
}
</script>

<style scoped>

</style>
